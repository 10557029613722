import { Model } from "@nozbe/watermelondb";
import { field, date, immutableRelation, children } from "@nozbe/watermelondb/decorators";

import Attachment from "./Attachment";
import Mention from "./Mention";
import Tag from "./Tag";

// @ts-ignore
export default class Message extends Model {
  static table = "messages";
  static associations = {
    channels: { type: "belongs_to", key: "channel_id" },
    teams: { type: "belongs_to", key: "team_id" },
    users: { type: "belongs_to", key: "from_user_id" },

    tags: { type: "has_many", foreignKey: "message_id" },
    attachments: { type: "has_many", foreignKey: "message_id" },
    mentions: { type: "has_many", foreignKey: "message_id" },
  };
  // Relationships
  @immutableRelation("channels", "channel_id") channel;
  @immutableRelation("teams", "team_id") team;
  @immutableRelation("users", "from_user_id") user;  

  @children("tags") tags;
  @children("attachments") attachments;
  @children("mentions") mentions;

  // Duplicate child fields to hold synchronous values
  attachments_: Attachment[] = [];
  mentions_: Mention[] = [];
  tags_: Tag[] = [];

  // Content
  @field("subject") subject;
  @field("summary") summary;
  @field("body_content") bodyContent;
  @field("body_content_type") bodyContentType;

  // Metadata
  @field("web_url") webUrl;
  @field("reply_to_id") replyToId;
  @date("created_at") createdAt; // Unix timestamp, for WatermelonDB
  @date('updated_at') updatedAt; // Unix timestamp, for WatermelonDB
}
