import db from "../db";
import { Q } from "@nozbe/watermelondb";

import Message from "../models/Message";
import Attachment from "../models/Attachment";
import Mention from "../models/Mention";
import Tag from "../models/Tag";

import compact from "lodash/compact";

import { parseISO, getTime } from "date-fns";

const prepareBatchMessages = async (rawMessages: any[]) => {
  const messageIds = rawMessages.map((m) => m.id);

  const messageCollection = db.collections.get<Message>("messages");

  const existingMessages = await messageCollection
    .query(Q.where("id", Q.oneOf(messageIds)))
    .fetch();
  const existingMessagesIds = existingMessages.map((m) => m.id);

  // Messages with IDs that are not contained in existingMessages
  const rawMessagesToCreate = rawMessages.filter(
    (m) => !existingMessagesIds.includes(m.id)
  );
  const notExistingRawMessagesIds = rawMessagesToCreate.map((m) => m.id);

  // Remaining messages that are not contained in messagesToCreate
  const rawMessagesToUpdate = rawMessages.filter(
    (m) => !notExistingRawMessagesIds.includes(m.id)
  );

  const messagesToCreate = [] as any[];
  const attachmentsToCreate = [] as any[];
  const mentionsToCreate = [] as any[];
  const tagsToCreate = [] as any[];

  const messagesToUpdate = [] as any[];

  rawMessagesToCreate.forEach((msg) => {
    messagesToCreate.push(
      messageCollection.prepareCreate((message) => {
        message._raw.id = msg.id;
        // Content
        message.subject = msg.subject;
        // @ts-ignore
        message.summary = msg.summary;
        message.bodyContent = msg.body.content;
        message.bodyContentType = msg.body.contentType;
        // Metadata
        message.webUrl = msg.webUrl;
        // @ts-ignore
        message.replyToId = msg.replyToId;
        message.createdAt = getTime(parseISO(msg.createdDateTime));
        // Relationships
        // @ts-ignore
        message._setRaw("channel_id", msg.channelIdentity.channelId);
        // @ts-ignore
        message._setRaw("team_id", msg.channelIdentity.teamId);
        // @ts-ignore
        message._setRaw("from_user_id", msg.from.user.id);
      })
    );

    if (msg.attachments?.length) {
      compact(msg.attachments).forEach((a) => {
        attachmentsToCreate.push(
          db.collections
            .get<Attachment>("attachments")
            .prepareCreate((attachment) => {
              // @ts-ignore
              attachment._raw.id = a.id;
              // Content
              // @ts-ignore
              attachment.content = a.content;
              // @ts-ignore
              attachment.contentType = a.contentType;
              // @ts-ignore
              attachment.contentUrl = a.contentUrl;
              // @ts-ignore
              attachment.name = a.name;
              // @ts-ignore
              attachment.thumbnailUrl = a.thumbnailUrl;
              // Metadata
              // @ts-ignore
              attachment._setRaw("message_id", msg.id);
            })
        );
      });
    }
    if (msg.mentions?.length) {
      compact(msg.mentions).forEach((mn) => {
        mentionsToCreate.push(
          db.collections.get<Mention>("mentions").prepareCreate((mention) => {
            // @ts-ignore
            mention._raw.id = `${msg.id}_${mn.id}`;
            // Content
            // @ts-ignore
            mention.mentionText = mn.mentionText;
            // Metadata
            // @ts-ignore
            mention._setRaw("mentioned_user_id", mn.mentioned.user.id);
            // @ts-ignore
            mention._setRaw("message_id", msg.id);
          })
        );
      });
    }
    if (msg.tags?.length) {
      compact(msg.tags).forEach((t) => {
        tagsToCreate.push(
          db.collections.get<Tag>("tags").prepareCreate((tag) => {
            // @ts-ignore
            tag._raw.id = t.id;
            // Content
            // @ts-ignore
            tag.addedMethod = t.addedMethod;
            // @ts-ignore
            tag.tag = t.tag;
            // @ts-ignore
            tag.text = t.text;
            // Metadata
            // @ts-ignore
            tag.isPrivate = t.isPrivate;
            // @ts-ignore
            tag.createdAt = getTime(parseISO(t.createdDateTime));
            // Relationships
            // @ts-ignore
            tag._setRaw("user_id", t.userId);
            // @ts-ignore
            tag._setRaw("message_id", msg.id);
          })
        );
      });
    }
  });

  rawMessagesToUpdate.forEach((msg) => {
    const message = existingMessages.find((m) => m.id === msg.id);
    messagesToUpdate.push(
      message?.prepareUpdate(() => {
        // Content
        message.subject = msg.subject;
        // @ts-ignore
        message.summary = msg.summary;
        message.bodyContent = msg.body.content;
        message.bodyContentType = msg.body.contentType;
        // Metadata
        message.webUrl = msg.webUrl;
        message.updatedAt = getTime(parseISO(msg.lastModifiedDateTime));
      })
    );
  });

  const messagesToDelete = []; // TODO

  // Return the create/update lists so that we can use this function in generate.ts as well.
  return {
    prepareCreateList: [
      ...messagesToCreate,
      ...attachmentsToCreate,
      ...mentionsToCreate,
      ...tagsToCreate,
    ],
    prepareUpdateList: [...messagesToUpdate],
    prepareDeleteList: [...messagesToDelete],
  };
};

export { prepareBatchMessages };
