// import { Type } from 'class-transformer';

// https://github.com/uuidjs/uuid
import { v4 as guid } from "uuid";

import showingMessagesItems from "../data/showingMessagesItems";

import Filter from "./Filter";

class Column {
  id: string = guid();

  customTitle: string = "";
  customIconName: any = {};

  // @Type(() => Filter)
  filter: Filter = new Filter();

  isSelected: boolean = false;

  constructor(id?: string) {
    this.id = id || guid();
  }

  get title() {
    if (this.customTitle?.length) return this.customTitle;

    return this.getTitleOrIconName("title");
  }

  get icon() {
    const iconName = this.customIconName?.length
      ? this.customIconName
      : this.getTitleOrIconName("iconName");
    return { label: iconName, value: iconName };
  }

  set icon(newIcon: any) {
    this.customIconName = newIcon?.value;
  }

  getTitleOrIconName(titleOrIconName: string): string {
    let title = "";
    let iconName = "";

    // messageContent: {
    //   showing: string | undefined,
    //   matching: any[] | undefined,
    //   excluding: any[] | undefined,
    if (this.filter?.messageContent?.showing?.length) {
      if (this.filter?.messageContent?.showing !== showingMessagesItems[0]) {
        title += this.filter.messageContent.showing;

        switch (this.filter.messageContent.showing) {
          // "Messages with tags",
          case showingMessagesItems[1]:
            iconName = "TagIcon";
            break;
          // "Messages with attachments",
          case showingMessagesItems[2]:
            iconName = "PaperclipIcon";
            break;
          // "Messages with images",
          case showingMessagesItems[3]:
            iconName = "FilesImageIcon";
            break;
          // "Messages with gifs",
          case showingMessagesItems[4]:
            iconName = "GiphyIcon";
            break;
          // "Messages with links",
          case showingMessagesItems[5]:
            iconName = "LinkIcon";
            break;

          default:
            break;
        }
      }
    }

    if (this.filter?.messageContent?.matching?.length) {
      title += title.length ? " | Matching: " : "";
      title += this.filter.messageContent.matching
        .map((m) => m.label)
        .join(", ");
      iconName = "TagIcon";
    }

    if (this.filter?.messageContent?.excluding?.length) {
      title += title.length ? " | Excluding: " : "Excluding: ";
      title += this.filter.messageContent.excluding
        .map((m) => m.label)
        .join(", ");
      iconName = "TagIcon";
    }

    // users: {
    //   by: any | undefined,
    //   bySpecificUsers: any[] | undefined,
    //   mentioning: any | undefined,
    //   mentioningSpecificUsers: any[] | undefined,
    if (
      this.filter?.users?.by &&
      (this.filter?.users?.by?.value === "me" ||
        this.filter?.users?.bySpecificUsers?.length)
    ) {
        title += title.length ? " | By: " : "By: ";

        if (this.filter?.users?.by?.value === "me") {
          title += "Me";
          iconName = "SpeakerPersonIcon";
        } else {
          let specificUsers = this.filter?.users?.bySpecificUsers
            ?.map((u) => u.label)
            .join(", ");
          title += specificUsers;
          iconName = "UserFriendsIcon";
      }
    }

    if (
      this.filter?.users?.mentioning &&
      (this.filter?.users?.mentioning?.value === "me" ||
        this.filter?.users?.mentioningSpecificUsers?.length)
    ) {
      title += title.length ? " | Mentioning: " : "Mentioning: ";

      if (this.filter?.users?.mentioning?.value === "me") {
        title += "Me";
        iconName = "MentionIcon";
      } else {
        let specificUsers = this.filter?.users?.mentioningSpecificUsers
          ?.map((u) => u.label)
          .join(", ");
        title += specificUsers ?? "";
        iconName = "MentionIcon";
      }
    }    

    // teamsAndChannels: {
    //   selected: any[] | undefined,
    //   teamIds: string[] | undefined,
    //   channelIds: string[] | undefined,
    if (this.filter?.teamsAndChannels?.selected?.length) {
      title += title.length ? " | In: " : "In: ";
      iconName = "ContactGroupIcon";

      let selectedLabels = this.filter.teamsAndChannels.selected
        .map((s) => {
          if (s.parentLabel) {
            return `${s.parentLabel} > ${s.label}`;
          }
          return s.label;
        })
        .join(", ");
      title += selectedLabels;
    }

    if (titleOrIconName === "title") {
      return title?.length ? title : showingMessagesItems[0];
    } else {
      return iconName?.length ? iconName : "SearchIcon";
    }
  }
}

export default Column;
