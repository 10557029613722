import "reflect-metadata";

import React from "react";
import ReactDOM from "react-dom";

// import { Debug } from "@fluentui/react-northstar";
// import { TelemetryPopover } from "@fluentui/react-telemetry";

import * as microsoftTeams from "@microsoft/teams-js";

import { Icon } from "@fluentui/react/lib/Icon";
import { initializeIcons } from "@uifabric/icons";

import { QueryClient, QueryClientProvider } from "react-query";

import { Providers, TeamsProvider } from "@microsoft/mgt";

import { makeServer } from "./server";

import reportWebVitals from "./reportWebVitals";

import appStore from "./stores/appStore";
import AppStore from "./models/AppStore";
import { hydrateAppStoreInstanceFromClass } from "./helpers/appStoreHelpers";

import DatabaseProvider from "@nozbe/watermelondb/DatabaseProvider";
import db from "./db/db";

import TeamsProviderWrapper from "./TeamsProviderWrapper";

import "react-gif-player/dist/gifplayer.css";

import "./index.css";

// Use Mirage for mocked data
if (process.env.REACT_APP_USE_MOCKED_DATA) {
  makeServer({ environment: "development" });
}

// https://docs.microsoft.com/en-us/graph/toolkit/get-started/build-a-microsoft-teams-tab
TeamsProvider.microsoftTeamsLib = microsoftTeams;
Providers.globalProvider = new TeamsProvider({
  clientId: "2441e82a-5e30-4c02-a442-1431f00f29c0",
  authPopupUrl: "/auth.html",
});

const queryClient = new QueryClient();

// Call the initialize API first
console.log("Initializing Teams...");
microsoftTeams.initialize();

// Init @uifabric/icons
initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    {/* <TelemetryPopover> */}
    <QueryClientProvider client={queryClient}>
      <DatabaseProvider database={db}>
        {/* <p>Generating DB...</p> */}
        <TeamsProviderWrapper />
        {/* @fluentui/react-northstar debugging */}
        {/* <Debug /> */}
      </DatabaseProvider>
    </QueryClientProvider>
    {/* </TelemetryPopover> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
