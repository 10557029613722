import { Model } from "@nozbe/watermelondb";
import { field, children } from "@nozbe/watermelondb/decorators";

// @ts-ignore
export default class User extends Model {
  static table = "users";
  static associations = {
    messages: { type: "has_many", key: "user_id" },
  };
  @children("messages") messages;

  @field("display_name") displayName;
  @field("user_principal_name") userPrincipalName;
}
