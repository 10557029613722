import { Model } from "@nozbe/watermelondb";
import { field, json, immutableRelation } from "@nozbe/watermelondb/decorators";

// @ts-ignore
export default class Attachment extends Model {
  static table = "attachments";
  static associations = {
    messages: { type: "belongs_to", key: "message_id" },
  };
  @immutableRelation("messages", "message_id") message;

  @json("content", json => json) content;
  @field("content_type") contentType;
  @field("content_url") contentUrl;
  @field("name") name;
  @field("thumbnail_url") thumbnailUrl;
}

// "content": "{\"exchangeId\":\"AQMkADQ4Mjg2ZWZkLTY2AGIyLTRlYzAtYTcwNC1lN2ZhYjUwYjA2MjAARgAAAwORQRzIsjpHumvzKuG99p8HALaiDLA7wY9IuRiV5quL3GgAAAIBDQAAALaiDLA7wY9IuRiV5quL3GgAAAIJQAAAAA==\",\"organizerId\":null}",
// "contentType": "meetingReference",
// "contentUrl": null,
// "id": "1501528508682-1",
// "name": "Legal Round Up",
// "thumbnailUrl": null