import { Model } from "@nozbe/watermelondb";
import { field, immutableRelation } from "@nozbe/watermelondb/decorators";

// @ts-ignore
export default class Channel extends Model {
  static table = "channels";
  static associations = {
    teams: { type: "belongs_to", key: "team_id" },
  };
  @immutableRelation("teams", "team_id") team;

  @field("display_name") displayName;
  @field("description") description;
  @field("web_url") webUrl;
}
