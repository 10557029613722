import React, { useState, useEffect, Suspense } from "react";
// import { useAsyncEffect } from "use-async-effect";

import * as microsoftTeams from "@microsoft/teams-js";

import {
  Provider,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-northstar";

import pick from "lodash/pick";

import { view, autoEffect } from "@risingstack/react-easy-state";

import appStore from "./stores/appStore";
import AppStore from "./models/AppStore";
import { hydrateAppStoreInstanceFromClass } from "./helpers/appStoreHelpers";

import { useHotkeys } from "react-hotkeys-hook";

import Preloader from "./Preloader";

const TeamsProviderWrapper = view(() => {
  // const [currentTheme, setCurrentTheme] = useState(teamsTheme);
  // const [currentTheme, setCurrentTheme] = useState(teamsDarkTheme);
  // const [currentTheme, setCurrentTheme] = useState(teamsHighContrastTheme);

  const urlParams = new URLSearchParams(window.location.search);
  const [currentTheme, setCurrentTheme] = useState(
    urlParams.get("theme") === "dark" ? teamsDarkTheme : teamsTheme
  );

  // TODO: Set colors from theme as css variables for use in external components?
  // currentTheme.siteVariables.colorScheme => .default / .brand
  useEffect(() => {
    console.log("Theme changed to:", currentTheme);

    const colorScheme = currentTheme.siteVariables.colorScheme;
    const defaultColorScheme = colorScheme.default;
    const brandColorScheme = colorScheme.brand;

    console.log("defaultColorScheme:", defaultColorScheme);
    console.log("brandColorScheme:", brandColorScheme);

    const filteredDefaultColorScheme = pick(defaultColorScheme, [
      "foreground",
      "foreground1",
      "foreground2",
      "background",
      "background3",
      "backgroundHover",
      "backgroundHover4",
    ]);
    const filteredBrandColorScheme = pick(brandColorScheme, [
      "foreground",
      "background",
    ]);

    for (const [key, value] of Object.entries(filteredDefaultColorScheme)) {
      // theme.siteVariables.colorScheme.default.foreground1
      const cssVarKey = `--theme-siteVariables-colorScheme-default-${key}`;
      const cssVarValue = `${value}`;
      console.log(`${cssVarKey}: ${cssVarValue}`);
      document.documentElement.style.setProperty(cssVarKey, cssVarValue);
    }

    for (const [key, value] of Object.entries(filteredBrandColorScheme)) {
      const cssVarKey = `--theme-siteVariables-colorScheme-brand-${key}`;
      const cssVarValue = `${value}`;
      console.log(`${cssVarKey}: ${cssVarValue}`);
      document.documentElement.style.setProperty(cssVarKey, cssVarValue);
    }
  }, [currentTheme]);

  useHotkeys("alt+t", () => setTheme("dark"));
  useHotkeys("shift+alt+t", () => setTheme(""));

  // Setup Teams registrations
  useEffect(() => {
    console.log("Getting Teams context...");
    microsoftTeams.getContext((context) => {
      microsoftTeams.appInitialization.notifySuccess();
      // debugger;

      // Sample context: {
      // appIconPosition: 420
      // appSessionId: "8ce24063-d5eb-4bb2-a372-9ffa5f5a1de5"
      // chatId: ""
      // entityId: "43287b18-871c-4f19-82a2-986388536409"
      // frameContext: "content"
      // hostClientType: "web"
      // isFullScreen: false
      // isMultiWindow: false
      // jsonTabUrl: "microsoft-teams-json-tab.azurewebsites.net"
      // locale: "en-us"
      // loginHint: "ed@gobionic.io"
      // meetingId: ""
      // ringId: "general"
      // sessionId: "f3fb5488-075e-ee65-44b2-d67575129cfe"
      // subEntityId: "#meeting"
      // teamSiteDomain: "plumage.sharepoint.com"
      // teamSitePath: ""
      // teamSiteUrl: ""
      // tenantSKU: "unknown"
      // theme: "default"
      // tid: "a6efc42b-4c6e-43f4-bb6b-f5a92edf750f"
      // upn: "ed@gobionic.io"
      // userLicenseType: "Unknown"
      // userObjectId: "32f5c3de-f8d7-4687-bc93-08fdb8209044"
      // userPrincipalName: "ed@gobionic.io"
      // }

      console.log("Teams context: ", context);

      // Handle clearing localForage data if user context changes
      // Check if loggedInUser IDs are different
      if (
        appStore.loggedInUser?.id &&
        appStore.loggedInUser?.id !== context.userObjectId
      ) {
        console.warn("New user logged in, resetting appStore");
        const newAppStoreClass = new AppStore();
        hydrateAppStoreInstanceFromClass(appStore, newAppStoreClass);
      }

      appStore.context = context;

      appStore.loggedInUser = {
        id: context.userObjectId,
        userPrincipalName: context.userPrincipalName,
        displayName: undefined,
        mail: undefined,
      };

      // Check the initial theme user chose and respect it
      if (context?.theme) {
        setTheme(context.theme);
      }

      // Check to see if a "subEntityId" was passed through,
      // e.g. if the tab was opened via a deeplink from a tag

      // DeepLink URL format:
      // https://teams.microsoft.com/l/entity/<appId>/<entityId>?webUrl=<entityWebUrl>&label=<entityLabel>&context=<context>

      // { "messageContent": { "matching": [ { "label": "#meeting", "value": "#meeting" } ] } }
      // "eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0="

      // Pre base64 encoded context.subEntityId:
      // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https://gobionic.ngrok.io/&label=#meeting&context={"subEntityId": { "messageContent": { "matching": [ { "label": "#meeting", "value": "#meeting" } ] } } }

      // Post base64 encoded context.subEntityId: {"filter": "#meeting"} => "eyJmaWx0ZXIiOiAiI21lZXRpbmcifQ=="
      // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https://gobionic.ngrok.io/&label=#meeting&context={"subEntityId": "eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0="}

      // Post URI encoding of params: (e.g. encodeURI() )
      // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https%3A%2F%2Fgobionic.ngrok.io%2F&label=%23meeting&context=%7B%22subEntityId%22:%20%22eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0=%22%7D

      if (context?.subEntityId) {
        // subEntityId will be base64 encoded string
        console.log("Teams context subEntityId: Original", context.subEntityId);

        // Decode from base64
        const subEntityIdFromBase64 = atob(context.subEntityId);
        console.log(
          "Teams context subEntityId: Decoded from base64",
          subEntityIdFromBase64
        );

        // After base64 decoding, it needs to be parsed as a JSON obj
        const subEntityIdObj = JSON.parse(subEntityIdFromBase64);
        console.log(
          "Teams context subEntityId: Parsed as JSON obj",
          subEntityIdObj
        );

        // appStore.columns = [
        //   plainToClassFromExist(new Column(), {
        //     isSelected: true,
        //     filter: subEntityIdObj
        //   }),
        // ];

        // TODO: Make scrolling to selected work after App.tsx loads
        appStore.addColumn({
          filter: subEntityIdObj,
        });

        console.log(
          "Teams context subEntityId: appStore.columns:",
          appStore.columns
        );
      }
    });

    // Handle theme changes
    microsoftTeams.registerOnThemeChangeHandler(function (theme: string) {
      setTheme(theme);
    });
  }, []);

  function setTheme(theme: string) {
    // teamsTheme,
    // teamsDarkTheme,
    // teamsHighContrastTheme,

    switch (theme) {
      case "dark":
        setCurrentTheme(teamsDarkTheme);
        break;
      case "contrast":
        setCurrentTheme(teamsHighContrastTheme);
        break;
      default:
        setCurrentTheme(teamsTheme);
        break;
    }
  }

  return (
    <Provider theme={currentTheme}>
      <Preloader />
      {/* @fluentui/react-northstar debugging */}
      {/* <Debug /> */}
    </Provider>
  );
});

export default TeamsProviderWrapper;
