import { appSchema, tableSchema } from "@nozbe/watermelondb";

export default appSchema({
  version: 1,
  tables: [
    // Teams
    /* 
    "id": "02bd9fd6-8f93-4758-87c3-1fb73740a315",
    "deletedDateTime": null,
    "createdDateTime": "2017-07-31T18:56:16Z",
    "description": "Welcome to the HR Taskforce team.",
    "displayName": "HR Taskforce",
    "expirationDateTime": null,
    "visibility": "Private",    
    "webUrl": "https://teams.microsoft.com/l/team/19:264b6913b53a4cf79528114fe225ea24%40thread.tacv2/conversations?groupId=9c513eeb-48a2-4e3b-b9bf-4303560b0b41&tenantId=8debc83d-9ac4-4a2a-8386-c788dcc1d4bb",    
    */
    tableSchema({
      name: "teams",
      columns: [
        { name: "display_name", type: "string" },
        { name: "description", type: "string" },
        { name: "web_url", type: "string" },
        { name: "general_channel_id", type: "string", isOptional: true },
      ],
    }),

    // Channels
    /* 
    "displayName": "General",
    "webUrl": "https://teams.microsoft.com/l/channel/19%3a264b6913b53a4cf79528114fe225ea24%40thread.tacv2/General?groupId=9c513eeb-48a2-4e3b-b9bf-4303560b0b41&tenantId=8debc83d-9ac4-4a2a-8386-c788dcc1d4bb",
    "teamId": "9c513eeb-48a2-4e3b-b9bf-4303560b0b41",
    "createdDateTime": "2021-02-28T22:22:01.343+00:00"
    "deletedDateTime": null,    
    */
    tableSchema({
      name: "channels",
      columns: [
        { name: "display_name", type: "string" },
        { name: "description", type: "string" },
        { name: "web_url", type: "string" },
        { name: "team_id", type: "string", isIndexed: true },
      ],
    }),

    // Users
    /* 
    "id": "3b20ac01-6c88-43db-8c9f-035beae21a22",
    "displayName": "Adele Vance",
    "userPrincipalName": "AdeleV@gobionicdev.onmicrosoft.com"    
    */
    tableSchema({
      name: "users",
      columns: [
        { name: "display_name", type: "string" },
        { name: "user_principal_name", type: "string" },
      ],
    }),

    // Messages
    /* 
    "id": "1614551102346",
    "subject": null,
    "summary": null,
    "body": {
      "content": "<div>Hi team, please remember to update each of your #targets in our&nbsp;opportunity tracker. I have a #review meeting scheduled Monday of next week. #action-item</div>",
      "contentType": "html",
    },
    "channelIdentity": {
      "channelId": "19:d9d18a5d8fe3492f93ee716deafcaab7@thread.tacv2",
      "teamId": "9c513eeb-48a2-4e3b-b9bf-4303560b0b41",
    },    
    "from": {
      "user": {
        "displayName": "Henrietta Mueller",
        "id": "cc0e2a63-44cc-41f4-a6e4-68eae07e5ede",
      },
    },    
    "replyToId": null,
    "webUrl": "https://teams.microsoft.com/l/message/19%3Ad9d18a5d8fe3492f93ee716deafcaab7%40thread.tacv2/1614551102346?groupId=9c513eeb-48a2-4e3b-b9bf-4303560b0b41&tenantId=8debc83d-9ac4-4a2a-8386-c788dcc1d4bb&createdTime=1614551102346&parentMessageId=1614551102346",

    "chatId": null,
    "createdDateTime": "2021-02-28T22:25:02.346+00:00",
    "deletedDateTime": null,
    "etag": "1614551102346",
    "importance": "normal",
    "lastEditedDateTime": null,
    "lastModifiedDateTime": "2021-02-28T22:25:02.346+00:00",
    "locale": "en-us",
    "policyViolation": null,

    "mentions": [], <= Use mentions table
    "attachments": [], <= Use attachments table
    "reactions": [], <= Use reactions table

    "isReply": false,  
    "isDeleted": false,
    "hasPolicyViolation": false,

    "reactionCount": 0,
    "attachmentCount": 0,    
    */
    tableSchema({
      name: "messages",
      columns: [
        // Content
        { name: "subject", type: "string", isOptional: true },
        { name: "summary", type: "string", isOptional: true },
        { name: "body_content", type: "string" },
        { name: "body_content_type", type: "string" },
        // Metadata
        { name: "web_url", type: "string" },
        { name: "reply_to_id", type: "string", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },        
        // Relationships
        { name: "channel_id", type: "string", isIndexed: true }, // FK
        { name: "team_id", type: "string", isIndexed: true }, // FK
        { name: "from_user_id", type: "string", isIndexed: true }, // FK
      ],
    }),

    // Mentions
    // "id": 0,
    // "mentioned": {
    //   "application": null,
    //   "device": null,
    //   "user": {
    //     "displayName": "Megan Bowen",
    //     "id": "d19d7e89-c3a0-4231-870d-e26b19dee89b",
    //   },
    // },
    // "mentionText": "Megan Bowen",  
    tableSchema({
      name: "mentions",
      columns: [
        // Content
        { name: "mention_text", type: "string" },
        // Relationships
        { name: "message_id", type: "string", isIndexed: true }, // FK
        { name: "mentioned_user_id", type: "string", isIndexed: true }, // FK
      ],
    }),           

    // Tags
    // "id": "0177eac4-4964-47c3-b3e2-9f10f24fb082",
    // "addedMethod": "regex",
    // "createdDateTime": "2021-02-28T22:31:08.3248537Z",
    // "isPrivate": false,
    // "messageId": "1614551102346",
    // "tag": "#review",
    // "text": "#review",
    // "userId": "cc0e2a63-44cc-41f4-a6e4-68eae07e5ede"   
    tableSchema({
      name: "tags",
      columns: [
        // Content
        { name: "tag", type: "string", isIndexed: true },
        { name: "text", type: "string" },
        // Metadata
        { name: "added_method", type: "string" },
        { name: "is_private", type: "boolean" },
        { name: "created_at", type: "number" },
        // Relationships
        { name: "message_id", type: "string", isIndexed: true }, // FK
        { name: "user_id", type: "string", isIndexed: true }, // FK
      ],
    }),     

    // Attachments
    // "content": "{\"exchangeId\":\"AQMkADQ4Mjg2ZWZkLTY2AGIyLTRlYzAtYTcwNC1lN2ZhYjUwYjA2MjAARgAAAwORQRzIsjpHumvzKuG99p8HALaiDLA7wY9IuRiV5quL3GgAAAIBDQAAALaiDLA7wY9IuRiV5quL3GgAAAIJQAAAAA==\",\"organizerId\":null}",
    // "contentType": "meetingReference",
    // "contentUrl": null,
    // "id": "1501528508682-1",
    // "name": "Legal Round Up",
    // "thumbnailUrl": null
    tableSchema({
      name: "attachments",
      columns: [
        // Content
        { name: "content_type", type: "string" },
        { name: "content_url", type: "string" },
        { name: "name", type: "string" },
        { name: "thumbnail_url", type: "string" },
        // Relationships
        { name: "message_id", type: "string", isIndexed: true }, // FK
      ],
    }),      

    // TODO: Reactions?
    // TODO: Images?
    // TODO: Links?
  ],
});
