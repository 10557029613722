import { Model } from "@nozbe/watermelondb";
import { field, immutableRelation } from "@nozbe/watermelondb/decorators";

import User from "./User";

// @ts-ignore
export default class Mention extends Model {
  static table = "mentions";
  static associations = {
    messages: { type: "belongs_to", key: "message_id" },
    users: { type: "belongs_to", key: "user_id" },
  };
  @immutableRelation("messages", "message_id") message;
  @immutableRelation("users", "mentioned_user_id") mentionedUser;

  // Duplicate child fields to hold synchronous values
  mentionedUser_: User = new User();

  @field("mention_text") mentionText;
}

// "id": 0,
// "mentioned": {
//   "application": null,
//   "device": null,
//   "user": {
//     "displayName": "Megan Bowen",
//     "id": "d19d7e89-c3a0-4231-870d-e26b19dee89b",
//   },
// },
// "mentionText": "Megan Bowen",