import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";

import schema from "./schema";

import Channel from "./models/Channel";
import Message from "./models/Message";
import Team from "./models/Team";
import User from "./models/User";
import Attachment from "./models/Attachment";
import Mention from "./models/Mention";
import Tag from "./models/Tag";

const adapter = new LokiJSAdapter({
  schema,
  // migrations, // optional migrations

  useWebWorker: false, // recommended for new projects. tends to improve performance and reduce glitches in most cases, but also has downsides - test with and without it

  // Need to add webpack transform before trying to use webworker
  // See: https://nozbe.github.io/WatermelonDB/Installation.html#web-setup
  // yarn add --dev worker-loader
  // + update webpack config
  //
  // useWebWorker: true,
  
  useIncrementalIndexedDB: true, // recommended for new projects. improves performance (but incompatible with early Watermelon databases)
  dbName: "TaglyDB", // optional db name
  onIndexedDBVersionChange: () => {
    // database was deleted in another browser tab (user logged out), so we must make sure we delete
    // it in this tab as well
    // It's recommended you implement this method:
    // if (checkIfUserIsLoggedIn()) {
    //   window.location.reload()
    // }
  },
  // Optional:
  // onQuotaExceededError: (error) => { /* do something when user runs out of disk space */ },
});

// Then, make a Watermelon database from it!
const db = new Database({
  adapter,
  modelClasses: [Channel, Message, Team, User, Attachment, Mention, Tag],
  actionsEnabled: true,
});

export default db;
