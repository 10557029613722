import { Model } from "@nozbe/watermelondb";
import { field, children, relation } from "@nozbe/watermelondb/decorators";

// @ts-ignore
export default class Team extends Model {
  static table = "teams";
  static associations = {
    channels: { type: "has_many", foreignKey: "team_id" },
  };
  @children("channels") channels;
  
  @field("display_name") displayName;
  @field("description") description;
  @field("web_url") webUrl;

  @relation("channels", "general_channel_id") generalChannel;
}
