import AppStore from "../models/AppStore";
// import Column from "../models/Column";

import { classToPlain } from 'class-transformer';

import {
  // recreatedAppStoreFromObj,
  hydrateAppStoreInstanceFromObj,
} from "../helpers/appStoreHelpers";

import { store } from "@risingstack/react-easy-state";
import { observe, raw } from "@nx-js/observer-util";

import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import localForage from "localforage";
import { extendPrototype } from "localforage-observable";
import Observable from "zen-observable";

const TAGLY_APP_STORE = "TaglyAppStore";

localForage.config({
  driver: localForage.INDEXEDDB,
  name: TAGLY_APP_STORE,
});

const localforage = extendPrototype(localForage);

let appStoreObj = new AppStore();
let appStore: AppStore = store(appStoreObj);

// Persist appStore to localStorage when its modified
// https://github.com/RisingStack/react-easy-state/issues/26#issuecomment-495955480
// Scheduler delays reactions (to avoid conflict with message parsing)
// https://github.com/nx-js/observer-util#reaction-scheduling
// TODO: Change to use queue-util: https://github.com/nx-js/queue-util
const timeoutScheduler = (reaction) => setTimeout(reaction, 1000);

// Dummy tracker var to trigger an observe function
// let lastUpdatedAt;

const appStoreStateObserver = observe(
  () => {
    // lastUpdatedAt = appStore.updatedAtStr;

    // Force stringification to trigger the observe function
    // const newItem = JSON.stringify(appStore);
    JSON.stringify(appStore);

    // console.log("Observe triggered...");

    // Debounce storage updates to prevent multiple simultaneous writes
    debouncedWriteAppStoreUpdateToStorage();
  },
  {
    // Set lazy to true so that this doesn't run before the async IIFE at the bottom
    lazy: true,
    scheduler: timeoutScheduler,
  }
);

const writeAppStoreUpdateToStorage = async () => {
  // console.log("Observe - writeAppStoreUpdateToStorage triggered...");

  const storedItem = (await localForage.getItem(TAGLY_APP_STORE)) as AppStore;

  // Need to use raw to get unproxied base obj
  const rawAppStore = classToPlain(raw(appStore));
  // const storedItemAppStore = recreatedAppStoreFromObj(storedItem);

  if (!isEqual(storedItem, rawAppStore)) {
    // console.log("Writing rawAppStore update to localForage:", {storedItem}, {rawAppStore});
    
    // Save to localForage
    await localForage.setItem(TAGLY_APP_STORE, rawAppStore);
  }
};

// Delays firing writeAppStoreUpdateToStorage until 1 sec after the last call to this
const debouncedWriteAppStoreUpdateToStorage = debounce(
  writeAppStoreUpdateToStorage,
  1000
);

// https://github.com/localForage/localForage-observable#cross-tab-change-detection
// Set the Observable to use
localforage.newObservable.factory = (fn) => new Observable(fn);
// Configure cross tab notification/detection
localforage.ready().then(() => {
  localforage.configObservables({
    crossTabNotification: true,
    crossTabChangeDetection: true,
  });

  // TypeScript will find `newObservable()` after the casting that `extendPrototype()` does
  const observable = localforage.newObservable({
    crossTabNotification: true,
  });

  observable.subscribe({
    next: function (args) {
      if (args.crossTabNotification) {
        console.log("localforage: Crosstab change", args);
        if (args.key === TAGLY_APP_STORE) {
          const storedItemObj = args.newValue as AppStore;
          hydrateAppStoreInstanceFromObj(appStore, storedItemObj);
          setTimeout(() => {
            appStore.scrollSelectedColumnIntoView(
              appStore.columns.find((c) => c.isSelected)?.id
            );
          }, 500);
        }
      } else {
        // console.log("localforage: Local change", args);
      }
    },
    error: function (err) {
      console.error("localforage: Error", err);
    },
    complete: function () {
      // console.log("localforage: Observable destroyed!");
    },
  });
});

(async () => {
  // If restoring from localForage, create a class for the entire store,
  // then use plainToClassFromExist to rehydrate it when loaded from localForage.
  // Otherwise, none of the class methods will be in place, e.g. for Column.
  const storedItemObj = (await localForage.getItem(
    TAGLY_APP_STORE
  )) as AppStore;

  hydrateAppStoreInstanceFromObj(appStore, storedItemObj);

  // console.log('appStore: ', JSON.stringify(appStore, null, "\t"));

  // TODO: Remove when proper auth is in place
  const one = 1;
  if (one === 1 || process.env.NODE_ENV === "development") {
    if (!appStore.context?.upn) {
      appStore.context = {
        locale: "en-us",
        theme: "default",
        entityId: "43287b18-871c-4f19-82a2-986388536409",
        subEntityId: "",
        isFullScreen: false,
        sessionId: "3e20ba65-ee75-21f6-58b9-9c9bd483bc01",
        chatId: "",
        meetingId: "",
        hostClientType: "desktop",
        tenantSKU: "unknown",
        jsonTabUrl: "microsoft-teams-json-tab.azurewebsites.net",
        userLicenseType: "Unknown",
        appSessionId: "77127393-1276-4bbc-99b0-efd758d59e5e",
        isMultiWindow: false,
        appIconPosition: 364,
        frameContext: "content",
        teamSiteDomain: "gobionicdev.sharepoint.com",
        teamSitePath: "",
        teamSiteUrl: "",
        ringId: "general",
        tid: "8debc83d-9ac4-4a2a-8386-c788dcc1d4bb",
        // loginHint: "GradyA@gobionicdev.onmicrosoft.com",
        // upn: "GradyA@gobionicdev.onmicrosoft.com",
        // userPrincipalName: "GradyA@gobionicdev.onmicrosoft.com",
        // userObjectId: "45ccade4-2c79-4480-b792-bcf196f82822",
        loginHint: "MeganB@gobionicdev.onmicrosoft.com",
        upn: "MeganB@gobionicdev.onmicrosoft.com",
        userPrincipalName: "MeganB@gobionicdev.onmicrosoft.com",
        userObjectId: "d19d7e89-c3a0-4231-870d-e26b19dee89b",
      };
    }

    if (!appStore.loggedInUser?.id) {
      // appStore.loggedInUser = {
      //   id: "45ccade4-2c79-4480-b792-bcf196f82822",
      //   userPrincipalName: "GradyA@gobionicdev.onmicrosoft.com",
      //   displayName: "Grady Archie",
      //   mail: "GradyA@gobionicdev.onmicrosoft.com",
      // };

      appStore.loggedInUser = {
        id: "d19d7e89-c3a0-4231-870d-e26b19dee89b",
        userPrincipalName: "MeganB@gobionicdev.onmicrosoft.com",
        displayName: "Megan Bowen",
        mail: "MeganB@gobionicdev.onmicrosoft.com",
      };
    }
  }

  // Trigger the lazy observer to initialize itself, to being watching for changes
  appStoreStateObserver();
})();

export default appStore;
