import {
  Server,
  // Model
} from "miragejs";

import { formatISO, sub } from "date-fns";
import cloneDeep from "lodash/cloneDeep";

// https://github.com/uuidjs/uuid
import { v4 as guid } from "uuid";

// import omit from "lodash/omit";
// import flatMap from "lodash/flatMap";

import messagesWithTags from "./mockData/messagesWithTags.json";

// import teams from "./mockData/teams.json";
// import channels from "./mockData/channels.json";
import teamsWithChannels from "./mockData/teamsWithChannels.json";
import users from "./mockData/users.json";

// Fake createdDateTime to decreasing days
let days = 0;
messagesWithTags.forEach((message) => {
  // const isoTimestamp = parseISO(message.createdDateTime);
  const fakedTimestamp = sub(new Date(), { days: days });
  message.createdDateTime = formatISO(fakedTimestamp);
  days++;
});

let msgId = Number(messagesWithTags[0].id);

const newMessage = (since: string) => {
  msgId++;

  // Simulate occasional results (once per minute at a query rate of 1 per second)
  if (msgId % 60 !== 0) return;

  let msg = {
    ...cloneDeep(messagesWithTags[0]),
    id: msgId.toString(),
    etag: msgId.toString(),
    createdDateTime: `${new Date().toISOString()}`,
    body: {
      content: `<div>New message: ${msgId} #test</div>`,
      contentType: "html",
    },
    tags: [
      {
        addedMethod: "regex",
        createdDateTime: `${new Date().toISOString()}`,
        id: guid(),
        isPrivate: false,
        messageId: msgId.toString(),
        tag: "#test",
        userId: "8b209ac8-08ff-4ef1-896d-3b9fde0bbf04",
      },
    ],
  };

  /*
  let msg = {
    id: msgId.toString(),
    etag: msgId.toString(),
    createdDateTime: `${new Date().toISOString()}`,
    attachmentCount: 0,
    attachments: [],
    body: {
      content: `<div>New message: ${msgId} #test</div>`,
      contentType: "html",
    },
    channelIdentity: {
      teamId: "20c20bf6-ebd3-413c-8b50-79e500efcca9",
      channelId: "19:e012e83652544a218d3f6ef5498d0b53@thread.tacv2",
      "@odata.type": "microsoft.graph.channelIdentity",
    },
    chatId: null,
    deletedDateTime: null,
    from: {
      user: {
        id: "d3259164-34af-4b92-9c2a-e9ea45ab641f",
        "@odata.type": "microsoft.graph.identity",
        displayName: "Ed Laver",
        userIdentityType: "aadUser",
      },
      "@odata.type": "microsoft.graph.identitySet",
      conversation: null,
    },
    hasPolicyViolation: false,
    importance: "normal",
    isDeleted: false,
    isReply: false,
    lastModifiedDateTime: null,
    locale: "en-us",
    mentions: [],
    messageType: "message",
    policyViolation: null,
    reactionCount: 0,
    reactions: [],
    replyCount: 0,
    replyToId: null,
    subject: null,
    summary: null,
    tags: [
      {
        addedMethod: "regex",
        createdDateTime: "2017-07-31T19:15:18.131Z",
        id: "18504d96-56c5-4990-8d7b-c957722e38f4",
        isPrivate: false,
        messageId: msgId.toString(),
        tag: "#test",
        userId: "8b209ac8-08ff-4ef1-896d-3b9fde0bbf04",
      },
    ],
    webUrl:
      "https://teams.microsoft.com/l/message/19%3Af48d252d76504c8a9155df43062f552a%40thread.skype/1501528518131?groupId=8090c93e-ba7c-433e-9f39-08c7ba07c0b3&tenantId=dcd219dd-bc68-4b9b-bf0b-4a33a796be35&createdTime=1501528518131&parentMessageId=1501528518131",
  };
  */

  return msg;
};

export function makeServer({ environment = "test" } = {}) {
  let server = new Server({
    environment,

    // models: {
    //   user: Model,
    // },

    // seeds(server) {
    //   server.create("user", { name: "Bob" });
    //   server.create("user", { name: "Alice" });
    // },

    routes() {
      this.namespace = "api";

      // this.get("/app/teams", () => {
      //   return teamsWithChannels.map((t) => omit(t, ["channels"]));
      // });

      // this.get("/app/channels", () => {
      //   return flatMap(teamsWithChannels, (t) => {
      //     return t.channels.map((c) => {
      //       return { ...c, teamId: t.id };
      //     });
      //   })
      // });

      this.get("/app/teamsWithChannels", () => {
        return teamsWithChannels;
      });

      this.get("/app/users", () => {
        return users;
      });

      this.get("/app/messages", (schema, request) => {
        if (request.queryParams.since) {
          return [];
          // var msg = newMessage(request.queryParams.since);
          // return msg ? [msg, messagesWithTags[0]] : [];
        }

        return messagesWithTags;
      });
      
    },
  });

  server.logging = false;

  return server;
}
