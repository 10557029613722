import { Model } from "@nozbe/watermelondb";
import { field, date, immutableRelation } from "@nozbe/watermelondb/decorators";

// @ts-ignore
export default class Tag extends Model {
  static table = "tags";
  static associations = {
    messages: { type: "belongs_to", key: "message_id" },
    users: { type: "belongs_to", key: "user_id" },
  };
  @immutableRelation("messages", "message_id") message;
  @immutableRelation("users", "user_id") user;

  @field("added_method") addedMethod;
  @field("is_private") isPrivate;
  @field("tag") tag;
  @field("text") text;

  @date("created_at") createdAt; // Unix timestamp, for WatermelonDB
}

// "id": "0177eac4-4964-47c3-b3e2-9f10f24fb082",
// "addedMethod": "regex",
// "createdDateTime": "2021-02-28T22:31:08.3248537Z",
// "isPrivate": false,
// "messageId": "1614551102346",
// "tag": "#review",
// "text": "#review",
// "userId": "cc0e2a63-44cc-41f4-a6e4-68eae07e5ede"