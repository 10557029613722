import React, { useState, useEffect, Suspense } from "react";
import { useAsyncEffect } from "use-async-effect";

import { Loader } from "@fluentui/react-northstar";

import { view, autoEffect } from "@risingstack/react-easy-state";

import omit from "lodash/omit";
import flatMap from "lodash/flatMap";
// import cloneDeep from "lodash/cloneDeep";

import db from "./db/db";
import generate from "./db/generate";
import Team from "./db/models/Team";
import User from "./db/models/User";
import Message from "./db/models/Message";

import appStore from "./stores/appStore";

// import AppStateWrapper from "./AppStateWrapper";

const Preloader = view(() => {
  const AppStateWrapper = React.lazy(() => import("./AppStateWrapper"));

  const [isLoading, setIsLoading] = useState(true);
  const [dbIsLoading, setDBIsLoading] = useState(true);
  const [userIsLoading, setUserIsLoading] = useState(true);

  const dbInitializedStateKey = "dbInitializedState";

  autoEffect(() => {
    console.log(
      "Checking user loaded state: ",
      appStore.loggedInUserId
    );
    setUserIsLoading(appStore.loggedInUserId === "");
  });

  // Set isLoading to false once db is loaded and user is logged in
  useEffect(() => {
    console.log(
      "Checking loaded states: ",
      { dbIsLoading },
      { userIsLoading }
    );
    setIsLoading(dbIsLoading && userIsLoading);
  }, [dbIsLoading, userIsLoading]);

  useAsyncEffect(async () => {
    if (userIsLoading) {
      console.log("Waiting for proper loggedInUserId...");
    } else {
      console.log("loggedInUserId found, continuing...");

      const dbInitializedState = await db.adapter.getLocal(
        dbInitializedStateKey
      );
      console.log("Checking dbInitializedState: ", dbInitializedState);
      if (!dbInitializedState || dbInitializedState !== "true") {
        console.log("Loading initial DB state");
        await loadInitialDBState();
        console.log("Initial DB loading state complete");
      }

      console.log("Setting dbIsLoading to false.  Preloading complete");
      setDBIsLoading(false);
    }
  }, [userIsLoading]);

  async function loadInitialDBState() {
    await runDbGeneration();

    // Set our initialized state to true,
    // so we won't need to load initial data next time
    await db.adapter.setLocal(dbInitializedStateKey, "true");
  }

  const runDbGeneration = async () => {
    // TODO: Add error handling...
    console.log(
      "Fetching initial data with loggedInUserId...",
      appStore.loggedInUserId
    );

    const loggedInUserId = appStore.loggedInUserId;
    const [teamsWithChannelsData, usersData, messagesData] = await Promise.all([
      fetch(
        `/api/app/teamsWithChannels?loggedInUserId=${loggedInUserId}`
      ).then((response) => response.json()),
      fetch(
        `/api/app/users?loggedInUserId=${loggedInUserId}`
      ).then((response) => response.json()),
      fetch(
        `/api/app/messages?loggedInUserId=${loggedInUserId}`
      ).then((response) => response.json()),
    ]);

    console.log(
      "Fetching initial data complete with loggedInUserId...",
      appStore.loggedInUserId
    );

    const teamsData = teamsWithChannelsData.map((t) => omit(t, ["channels"]));

    const channelsData = flatMap(teamsWithChannelsData, (t) => {
      return t.channels.map((c) => {
        return { ...c, teamId: t.id };
      });
    });

    const recordsCount = await generate(
      teamsData,
      channelsData,
      usersData,
      messagesData
    );

    console.log("db:recordsCount: ", recordsCount);

    // Query DB to see generate results
    // Teams / Channels
    const teamsCollection = db.collections.get<Team>("teams");
    const allTeams = await teamsCollection.query().fetch();
    // console.log("db:allTeams: ", allTeams);

    const teamsWithChannels = await Promise.all(
      allTeams.map(async (t) => {
        // const teamChannels = await t.channels.fetch();
        return {
          label: t.displayName,
          value: { id: t.id, type: "Team" },
          // children: teamChannels.map((c) => {
          children: (await t.channels).map((c) => {
            return {
              label: c.displayName,
              parentLabel: t.displayName,
              value: { id: c.id, type: "Channel" },
            };
          }),
        };
      })
    );
    console.log("db:teamsWithChannels: ", teamsWithChannels);

    // Users:
    const usersCollection = db.collections.get<User>("users");
    const allUsers = await usersCollection.query().fetch();
    // console.log("db:allUsers: ", allUsers);

    const allUserNames = allUsers.map((u) => {
      return u.displayName;
    });
    console.log("db:allUserNames: ", allUserNames);

    // Messages:
    const messagesCollection = db.collections.get<Message>("messages");
    const allMessages = await messagesCollection.query().fetch();
    // console.log("db:allMessages: ", allMessages);

    const allMessageSummaries = await Promise.all(
      allMessages.map(async (m) => {
        return {
          id: m.id,
          createdAt: m.createdAt,
          team: (await m.team).displayName,
          channel: (await m.channel).displayName,
          user: (await m.user).displayName,
        };
      })
    );
    console.log("db:allMessageSummaries: ", allMessageSummaries);

    // window.db = db;
  };

  if (isLoading) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <AppStateWrapper
        // @ts-ignore
        loggedInUserId={appStore.loggedInUserId}
      />
    </Suspense>
  );
});

export default Preloader;
