import Column from "../models/Column";
import Filter from "../models/Filter";

import byAuthorItems from "../data/byAuthorItems";
import mentioningAuthorItems from "../data/mentioningAuthorItems";
import showingMessagesItems from "../data/showingMessagesItems";

import {
  // plainToClass,
  plainToClassFromExist,
} from "class-transformer";

// https://github.com/uuidjs/uuid
import { v4 as guid } from "uuid";

import remove from "lodash/remove";

class AppStore {
  updatedAt: Date = new Date();
  // TODO: Move all other children under a "data" obj, to make it easier to rehydrate,
  // and watch without also watching the updatedAt prop, so we can update that on writes
  // without it triggering an infinite loop.
  // Then we can compare that prop before deciding to write to storage, to prevent having
  // to rehydrate the entire AppStore each time.
  context: any = {};
  loggedInUser: {
    // displayName: "Grady Archie" as string | undefined,
    // mail: "GradyA@gobionicdev.onmicrosoft.com" as string | undefined,
    // userPrincipalName: "GradyA@gobionicdev.onmicrosoft.com" as string | undefined,
    // id: "45ccade4-2c79-4480-b792-bcf196f82822" as string | undefined,
    displayName: string | undefined;
    mail: string | undefined;
    userPrincipalName: string | undefined;
    id: string | undefined;
  } = {
    displayName: undefined,
    mail: undefined,
    userPrincipalName: undefined,
    id: undefined,
  };
  columns: Column[] = [
    // All messages
    plainToClassFromExist(new Column(), {
      isSelected: true,
      // filter: plainToClassFromExist(new Filter(), {}),
    }),
    // By: Me
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        users: {
          by: byAuthorItems[1],
        },        
      }),
    }),        
    // Mentioning: Me
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        users: {
          mentioning: mentioningAuthorItems[1],
        },        
      }),
    }),        
    // Messages with tags
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          showing: showingMessagesItems[1],
        },
      }),
    }),
    // Messages with images
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          showing: showingMessagesItems[3],
        },
      }),
    }),    

    /*
    // TODO: Remove after Testing
    plainToClassFromExist(new Column(), {
      filter: {
        teamsAndChannels: {
          selected: [
            {
              id: "00a41238-c918-44e2-85d4-3a56a5ca38a8",
              label: "X1050 Launch Team",
              parentLabel: "",
              type: "Team",
            },
            {
              id: "19:025eefe5fc15433ba37f2dead57e8206@thread.tacv2",
              label: "Knowledge Share",
              parentLabel: "Business Development",
              type: "Channel",
            },
          ],
        },
      },
    }),
    plainToClassFromExist(new Column(), {
      filter: {
        teamsAndChannels: {
          selected: [
            {
              id: "00a41238-c918-44e2-85d4-3a56a5ca38a8",
              label: "X1050 Launch Team",
              parentLabel: "",
              type: "Team",
            },
          ],
        },
      },
    }),
    plainToClassFromExist(new Column(), {
      filter: {
        teamsAndChannels: {
          selected: [
            {
              id: "19:025eefe5fc15433ba37f2dead57e8206@thread.tacv2",
              label: "Knowledge Share",
              parentLabel: "Business Development",
              type: "Channel",
            },
          ],
        },
      },
    }),        
    */

    // plainToClassFromExist(new Column(), {
    //   customTitle: "Custom Title",
    //   icon: { label: "EmojiIcon", value: "EmojiIcon" },
    //   isSelected: true,
    // }),

    // new Column(),
    // plainToClassFromExist(new Column(), {
    //   // filter: plainToClassFromExist(new Filter(), {
    //   //   messageContent: {
    //   //     matching: [{ label: "#x1050", value: "#x1050" }],
    //   //   },
    //   // }),
    // }),
    /*
    plainToClassFromExist(new Column(), {
    filter: {
      teamsAndChannels: {
          selected: [
            {
              id: "8090c93e-ba7c-433e-9f39-08c7ba07c0b3",
              label: "X1050 Launch Team",
              parentLabel: "",
              type: "Team",
            },
            {
              id: "19:600955c0f5664fb884e68db1aa52129c@thread.skype",
              label: "Knowledge Share",
              parentLabel: "Business Development",
              type: "Channel",
            },
          ],
        },
      },
    }),
    plainToClassFromExist(new Column(), {
      customTitle: "Custom Title",
      icon: { label: "EmojiIcon", value: "EmojiIcon" },
      isSelected: true,
    }),
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [
            { label: "#X1050", value: "#X1050" },
            { label: "#web", value: "#web" },
            { label: "#action-item", value: "#action-item" },
            { label: "#all-hands", value: "#all-hands" },
          ],
          excluding: [{ label: "#meeting", value: "#meeting" }],
        },
      }),
    }),
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [{ label: "#meeting", value: "#meeting" }],
        },
        users: {
          by: byAuthorItems[1],
        },
      }),
    }),
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        users: {
          by: byAuthorItems[0],
          bySpecificUsers: [
            {
              label: "Isaiah Langer",
              value: "e3d0513b-449e-4198-ba6f-bd97ae7cae85",
            },
            {
              label: "Johanna Lorenz",
              value: "626cbf8c-5dde-46b0-8385-9e40d64736fe",
            },
          ],
        },
      }),
    }),
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        users: {
          mentioning: byAuthorItems[1],
        },
      }),
    }),
    plainToClassFromExist(new Column(), {
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [{ label: "#thanks", value: "#thanks" }],
        },
        users: {
          mentioning: byAuthorItems[0],
          mentioningSpecificUsers: [
            {
              label: "Isaiah Langer",
              value: "e3d0513b-449e-4198-ba6f-bd97ae7cae85",
            },
            {
              label: "Johanna Lorenz",
              value: "626cbf8c-5dde-46b0-8385-9e40d64736fe",
            },
          ],
        },
      }),
    }),
    plainToClass(Column, {
      customTitle: "#X1050",
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [{ label: "#X1050", value: "#X1050" }],
        },
      }),
    }),
    plainToClass(Column, {
      customTitle: "#web",
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [{ label: "#web", value: "#web" }],
        },
      }),
    }),
    plainToClass(Column, {
      customTitle: "#meeting",
      filter: plainToClassFromExist(new Filter(), {
        messageContent: {
          matching: [{ label: "#meeting", value: "#meeting" }],
        },
      }),
    }),
    */
  ];

  get updatedAtStr() {
    return this.updatedAt.toISOString();
  }

  get loggedInUserId() {
    return this.loggedInUser.id || "";
  }

  addColumn(columnProps = {}) {
    const newColumnId = guid();
    this.columns.push(
      plainToClassFromExist(new Column(newColumnId), {
        ...columnProps,
      })
    );
    this.setColumnAsSelected(newColumnId);
    this.updatedAt = new Date();
    return newColumnId;
  }

  removeColumn(columnId) {
    remove(this.columns, (col: any) => col.id === columnId);
    this.updatedAt = new Date();
  }

  setColumnAsSelected(columnId) {
    // Deselect all the other navItems
    this.columns.forEach((col) => {
      col.isSelected = false;
    });

    // Select the passed in column as selected
    const selectedCol = this.columns.find((col) => col.id === columnId);
    if (selectedCol) selectedCol.isSelected = true;

    // Scroll the selected column into view
    this.scrollSelectedColumnIntoView(columnId);
  }

  scrollSelectedColumnIntoView(columnId) {
    if (columnId) {
      setTimeout(() => {
        document
          .getElementById(columnId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  }
}

export default AppStore;
