import db from "./db";

import Team from "./models/Team";
import Channel from "./models/Channel";
import User from "./models/User";

import { prepareBatchMessages } from "./helpers/messageHelpers";

const generate = async (teamsData, channelsData, usersData, messagesData) => {
  return await db.action(async () => {
    // Reset the DB
    await db.unsafeResetDatabase();

    // Teams
    const teams = teamsData.map((t) => {
      return db.collections.get<Team>("teams").prepareCreate((team) => {
        team._raw.id = t.id;
        team.displayName = t.displayName;
        // @ts-ignore
        team.description = t.description;
        team.webUrl = t.webUrl;
        // @ts-ignore
        team._setRaw("general_channel_id", t.generalChannel.id || "");
      });
    });
    // Insert in batch
    console.log("Inserting teams in batch");
    await db.batch(...teams);

    // Channels
    const channels = channelsData.map((c) => {
      return db.collections
        .get<Channel>("channels")
        .prepareCreate((channel) => {
          channel._raw.id = c.id;
          channel.displayName = c.displayName;
          // @ts-ignore
          channel.description = c.description;
          channel.webUrl = c.webUrl;
          // @ts-ignore
          channel._setRaw("team_id", c.teamId);
        });
    });
    // Insert in batch
    console.log("Inserting channels in batch");
    await db.batch(...channels);

    // Users
    const users = usersData.map((u) => {
      return db.collections.get<User>("users").prepareCreate((user) => {
        user._raw.id = u.id;
        user.displayName = u.displayName;
        user.userPrincipalName = u.userPrincipalName;
      });
    });
    // Insert in batch
    console.log("Inserting users in batch");
    await db.batch(...users);

    // Messages / Attachments / Mentions / Tags
    const batchPrepareMessages = await prepareBatchMessages(
      messagesData
    );

    // Combine all initial data together
    const messageRecords = [...batchPrepareMessages.prepareCreateList];

    // Insert in batch
    console.log("Inserting messageRecords in batch");
    await db.batch(...messageRecords);

    console.log("Completed inserting initial records to db");
    // console.log(allRecords);

    return messageRecords.length;
  });
};

export default generate;
