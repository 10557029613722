import TeamsAndChannelsList from "./TeamsAndChannelsList";

import showingMessagesItems from '../data/showingMessagesItems';

class Filter {

  messageContent: {
    showing: string | undefined,
    matching: any[] | undefined,
    excluding: any[] | undefined,
  } = {
    showing: showingMessagesItems[0], // "All messages",
    matching: [],
    excluding: [],
  };

  users: {
    by: any | undefined,
    bySpecificUsers: any[] | undefined,
    mentioning: any | undefined,
    mentioningSpecificUsers: any[] | undefined,
  } = {
    by: undefined,
    bySpecificUsers: [],
    mentioning: undefined,
    mentioningSpecificUsers: []
  };

  teamsAndChannels: TeamsAndChannelsList = new TeamsAndChannelsList();

  preferences: {
    enableDesktopNotifications: boolean,
  } = {
    enableDesktopNotifications: false
  };

  // constructor() {
  // }

  // greet() {
  //   return "Hello, " + this.greeting;
  // }
}

export default Filter;