import TeamOrChannelNode from "./TeamOrChannelNode";

class TeamsAndChannelsList {
  selected: TeamOrChannelNode[] = [];

  // constructor() {
  // }

  get teamIds() {
    return this.selected?.filter((s) => s.type === "Team").map((t) => t.id);
  }

  get channelIds() {
    return this.selected?.filter((s) => s.type === "Channel").map((c) => c.id);
  }
}

export default TeamsAndChannelsList;
