import AppStore from "../models/AppStore";
import Column from "../models/Column";

import {
    // plainToClass,
    plainToClassFromExist,
  } from "class-transformer";
  

const recreatedAppStoreFromObj = (appStoreObj: AppStore | null) => {
    if (appStoreObj) {
      let appStoreClass = plainToClassFromExist(new AppStore(), appStoreObj);
      // Clear and re-create columns
      appStoreClass.columns = [];
      appStoreObj.columns.forEach((colObj) => {
        const newCol = plainToClassFromExist(new Column(), colObj);
        appStoreClass.columns.push(newCol);
      });
      return appStoreClass;
    }
    return null;
  };

const hydrateAppStoreInstanceFromClass = (appStoreInstance: AppStore | null, appStoreClass: AppStore | null) => {
  if (appStoreClass) {
    const appStoreClassObj = recreatedAppStoreFromObj(appStoreClass);

    // appStore = store(appStoreObj);
    if (appStoreClassObj && appStoreInstance) {
        appStoreInstance.updatedAt = appStoreClassObj.updatedAt;
        appStoreInstance.context = appStoreClassObj.context;
        appStoreInstance.loggedInUser = appStoreClassObj.loggedInUser;
        appStoreInstance.columns = appStoreClassObj.columns;
    }
  }
};

const hydrateAppStoreInstanceFromObj = (appStoreInstance: AppStore | null, obj: AppStore | null) => {
  if (obj) {
    const appStoreClassObj = recreatedAppStoreFromObj(obj);
    hydrateAppStoreInstanceFromClass(appStoreInstance, appStoreClassObj);
  }
};

export { recreatedAppStoreFromObj, hydrateAppStoreInstanceFromClass, hydrateAppStoreInstanceFromObj }